import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spacer,
  Spinner,
  Text,
  Alert,
  Divider,
} from "@chakra-ui/react";
import getApolloClient from "apollo/client";
import {
  GetMobileIdLoginStateQuery,
  GetMobileIdLoginStateQueryVariables,
  InitiateMobileIdLoginQuery,
  InitiateMobileIdLoginQueryVariables,
  MobileIdLoginMutation,
  MobileIdLoginMutationVariables,
  MobileIdState,
} from "apollo/generatedTypes";
import { getQueryState, QueryState } from "components/queryUtil";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const INITIATE_MOBILE_ID_LOGIN = gql`
  query initiateMobileIdLogin($username: String!, $message: String) {
    initiateMobileIdLogin(username: $username, message: $message) {
      transactionIds
    }
  }
`;

const GET_MOBILE_ID_LOGIN_STATE = gql`
  query getMobileIdLoginState($transactionIds: [String!], $username: String!) {
    getMobileIdLoginState(
      transactionIds: $transactionIds
      username: $username
    ) {
      state
      password
    }
  }
`;

const LOGIN_WITH_MOBILE_ID = gql`
  mutation mobileIdLogin($authCode: String!, $ip: String) {
    mobileIdLogin(authCode: $authCode, ip: $ip) {
      baseUrl
      id
      meta {
        loginError
        errorMessage
      }
    }
  }
`;

const client = getApolloClient();

const MobileIdLogin = ({
  ip,
  forgotUserNameEnabled,
  refreshInitialData,
}: {
  ip?: string;
  forgotUserNameEnabled: boolean;
  refreshInitialData: () => void;
}) => {
  const [credentials, handleCredentials] = useState<{
    username: string;
  }>({
    username: "",
  });

  const [initiateLogin, loginInitinationPayload] = useLazyQuery<
    InitiateMobileIdLoginQuery,
    InitiateMobileIdLoginQueryVariables
  >(INITIATE_MOBILE_ID_LOGIN, {
    client,
    fetchPolicy: "network-only",
  });

  const router = useRouter();

  const initiationState = getQueryState(loginInitinationPayload);

  const transactionIds =
    loginInitinationPayload?.data?.initiateMobileIdLogin?.transactionIds ?? [];

  const [loginWithMobileId, loginWithMobileIdPayload] = useMutation<
    MobileIdLoginMutation,
    MobileIdLoginMutationVariables
  >(LOGIN_WITH_MOBILE_ID, {
    client,
    onCompleted: async (data) => {
      refreshInitialData();
      router.push(
        `/${encodeURIComponent(
          data.mobileIdLogin?.baseUrl as string
        )}/postlogin`
      );
    },
  });

  const mobileIdLoginStatePayload = useQuery<
    GetMobileIdLoginStateQuery,
    GetMobileIdLoginStateQueryVariables
  >(GET_MOBILE_ID_LOGIN_STATE, {
    variables: {
      transactionIds: transactionIds as string[],
      username: credentials.username,
    },
    client,
    skip: !transactionIds.length || !credentials?.username,
    onCompleted: (data) => {
      if (data?.getMobileIdLoginState?.state === "COMPLETED") {
        loginWithMobileId({
          variables: {
            authCode: data?.getMobileIdLoginState?.password as string,
            ip,
          },
        });
      }
    },
    fetchPolicy: "network-only",
  });

  const { startPolling, stopPolling } = mobileIdLoginStatePayload;
  const loginStateState = getQueryState(mobileIdLoginStatePayload);

  useEffect(() => {
    if (initiationState === QueryState.FINISHED) {
      startPolling(1000);
    }
  }, [initiationState, startPolling]);

  useEffect(() => {
    if (loginStateState === QueryState.FINISHED) {
      if (
        mobileIdLoginStatePayload?.data?.getMobileIdLoginState?.state !==
        MobileIdState.Pending
      ) {
        stopPolling();

        return () => {
          stopPolling();
        };
      }
    }
  }, [
    mobileIdLoginStatePayload?.data?.getMobileIdLoginState?.state,
    stopPolling,
    loginStateState,
  ]);

  const loginState = getQueryState(loginWithMobileIdPayload);

  const { formatMessage } = useIntl();

  return (
    <Fragment>
      <Flex flex={1} background="brand.background" p="4">
        <Box width="100%">
          <Text
            mt="4"
            fontWeight="bold"
            as="h4"
            textTransform="uppercase"
            fontSize="12px"
          >
            <FormattedMessage
              id="login.loginWithMobileId"
              defaultMessage="Log in with Incentive.Online ID"
            />
          </Text>
          <Text mt="2" fontSize="12px">
            <FormattedMessage
              id="login.mobileIdLoginInfo"
              defaultMessage="Incentive.Online ID (authentication app) available at Google Play and App Store.
              If you haven't activated the app yet, please login with other available login method first."
            />
          </Text>
          <Spacer minHeight="6" />

          <FormControl>
            <FormLabel
              textTransform="uppercase"
              fontSize="12px"
              color="primary"
              id="username_label"
              htmlFor="username_input"
            >
              <FormattedMessage id="login.userName" defaultMessage="Username" />
            </FormLabel>

            <Input
              autoCapitalize="none"
              data-cy="loginUsernameMobileId"
              id="username_input_mobile_id"
              size="sm"
              name="username_mobile_id"
              type="text"
              onChange={(e) =>
                handleCredentials({
                  ...credentials,
                  username: e.target.value,
                })
              }
            />
          </FormControl>

          <Spacer minHeight="4" />

          {mobileIdLoginStatePayload?.data?.getMobileIdLoginState?.state ===
            "PENDING" && (
            <Fragment>
              <FormattedMessage
                id="login.mobileIdLoginPending"
                defaultMessage="Waiting for Incentive.Online ID login to complete..."
              />
            </Fragment>
          )}

          {mobileIdLoginStatePayload?.data?.getMobileIdLoginState?.state ===
            "FAILED" && (
            <Alert status="error">
              <FormattedMessage
                id="login.mobileIdLoginFailed"
                defaultMessage="Incentive.Online ID login failed"
              />
            </Alert>
          )}

          {loginState === QueryState.ERRORED && (
            <Alert status="error">
              <Text>
                <FormattedMessage
                  id="login.mobileIdLoginFailed"
                  defaultMessage="Incentive.Online ID login failed"
                />
              </Text>
            </Alert>
          )}

          <Flex mt="4" flexDirection="column">
            <Button
              isDisabled={
                mobileIdLoginStatePayload?.data?.getMobileIdLoginState
                  ?.state === "PENDING"
              }
              variant="solidBlue"
              data-cy="loginButton"
              type="submit"
              textTransform="uppercase"
              fontSize="small"
              size="sm"
              fontWeight="500"
              minWidth="100px"
              height="auto"
              whiteSpace="break-spaces"
              py="2"
              onClick={() => {
                if (credentials.username)
                  initiateLogin({
                    variables: {
                      username: credentials.username,
                      message: formatMessage({
                        id: "login.mobileIdLoginMessage",
                        defaultMessage: "Confirm login to Incentive",
                      }),
                    },
                  });
              }}
            >
              <FormattedMessage
                id="login.loginWithMobileIdButtonLabel"
                defaultMessage="Log in with Incentive.Online ID"
              />
              {initiationState === QueryState.IN_PROGRESS && (
                <Spinner ml="3" size="sm" />
              )}
            </Button>
            {initiationState === QueryState.ERRORED && (
              <Alert color="black" status="error" mt="2">
                <Text fontWeight="bold">
                  {loginInitinationPayload?.error?.message}
                </Text>
              </Alert>
            )}
          </Flex>
        </Box>
      </Flex>
      {forgotUserNameEnabled && (
        <Flex
          alignSelf="flex-end"
          flexDirection="column"
          width="100%"
          background="brand.background"
        >
          <Divider mt="4" />

          <Flex justifyContent="flex-end" alignItems="center" p="4">
            <Text fontSize="small" color="gray.700">
              <Link href={"/forgotUsername"}>
                <FormattedMessage
                  id="login.forgotUsername"
                  defaultMessage="Forgot username?"
                />
              </Link>
            </Text>
          </Flex>
        </Flex>
      )}
    </Fragment>
  );
};

export default MobileIdLogin;
