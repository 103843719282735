import { createIcon } from "@chakra-ui/react";

const AlarmIcon = createIcon({
  displayName: "AlarmIcon",
  viewBox: "0 0 28 24",
  path: (
    <path
      d="M27.6849 20.6256C28.5821 22.1253 27.456 24 25.6638 24H2.33597C0.540424 24 -0.580647 22.1223 0.314869 20.6256L11.9789 1.1243C12.8766 -0.376125 15.125 -0.373406 16.0211 1.1243L27.6849 20.6256ZM14 16.5938C12.7651 16.5938 11.7639 17.5591 11.7639 18.75C11.7639 19.9409 12.7651 20.9062 14 20.9062C15.235 20.9062 16.2361 19.9409 16.2361 18.75C16.2361 17.5591 15.235 16.5938 14 16.5938ZM11.877 8.84316L12.2376 15.2182C12.2545 15.5165 12.5103 15.75 12.8201 15.75H15.18C15.4898 15.75 15.7456 15.5165 15.7624 15.2182L16.123 8.84316C16.1413 8.52094 15.8752 8.25 15.5406 8.25H12.4594C12.1248 8.25 11.8588 8.52094 11.877 8.84316Z"
      fill="currentColor"
    />
  ),
});

export default AlarmIcon;
